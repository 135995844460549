exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contentful-page-slug-js": () => import("./../../../src/pages/{contentfulPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-page-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-contentful-projecturl-former-js": () => import("./../../../src/pages/projects/contentfulProjecturl-FORMER.js" /* webpackChunkName: "component---src-pages-projects-contentful-projecturl-former-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-all-projects-js": () => import("./../../../src/templates/allProjects.js" /* webpackChunkName: "component---src-templates-all-projects-js" */)
}

